const config = {
  REST_API: window.env.REST_API,
  subDomain: 'http://ui-{TITLE}.ubidex.bid',
  gatewayApiDomain: window.env.GATEWAY_API || 'http://stats.ugateway.xyz',
  usaTraffDomain: window.env.US_TRAFFIC_DOMAIN || 'http://us-rtb.ugateway.xyz',
  euTraffDomain: window.env.EU_TRAFFIC_DOMAIN || 'http://eu-rtb.ugateway.xyz',
  // usaTraffDomain: 'http://rtb-gateway.ubidex.xyz',
  // euTraffDomain: 'http://eu-rtb-gateway.ubidex.xyz',

  hashIds: {
    salt: 'QrS6CUSpQgze5ufW',
    ln: 6,
    alphabet: 'abcdefghjkmnpqrstuvwxyz123456789',
  },
};

export default config;
